import React from 'react';
import PropTypes from 'prop-types';
import SectionTitle from '../SectionTitle';

const CardLink = (props) => (
  <a className="card-link" href={props.link.url}>
    {props.link.label}
  </a>
);

CardLink.propTypes = {
  link: PropTypes.shape({
    url: PropTypes.string,
    label: PropTypes.string
  })
};

const CardGridItem = (props) => (
  <div className="card-grid-item">
    <SectionTitle text={props.title} />
    <div className="card-image-container">
      <img src={props.image.src} alt={props.image.caption} />
    </div>
    <div className="card-description">{props.description}</div>
    <div className="card-link">
      <CardLink link={props.link} />
    </div>
  </div>
);

CardGridItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string,
    caption: PropTypes.string
  }),
  link: PropTypes.shape({
    url: PropTypes.string,
    label: PropTypes.string
  })
};

export default CardGridItem;
