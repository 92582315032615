import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/bulma/BulmaLayout';
import SectionWrapper from '../components/bulma/BulmaSectionWrapper';
import Content from '../components/Content';
import PageTitle from '../components/PageTitle';
import SectionTitle from '../components/SectionTitle';
import CardGridItem from '../components/cards/CardGridItem';

const BasaltActivitiesPage = ({ data }) => {
  const site = data.site;
  const page = data.basalt;
  const content = data.basalt.content.childMarkdownRemark;

  return (
    <Layout>
      <Helmet>
        <title>
          {site.siteTitle} - {content.frontmatter.title}
        </title>
        <meta name="description" content={content.frontmatter.metaDescription} />
      </Helmet>
      <SectionWrapper>
        <PageTitle text={content.frontmatter.title} />
        <div className="columns">
          <div className="column is-two-thirds">
            <div className="img-container">
              <img src={page.imageBasalt.src} alt={page.imageBasalt.caption} />
            </div>
            <Content html={content.html} />
          </div>
          <div className="column">
            <div className="card fly-fishing-sidebar">
              <div className="card-title has-text-centered has-dark-bg">
                <SectionTitle text="Premiere Fly Fishing" />
              </div>
              <div className="info-card-content">
                <p>Basalt Colorado is well know as a premiere Colorado fly fishing destination.</p>
                <p>
                  Centrally located, Basalt offers access to the Frying Pan, Roaring Fork, Crystal, and Colorado Rivers
                  all within a short drive.
                </p>
                <p>
                  Book a full day guide trip with Frying Pan Anglers and receive a discount at any of our lodging
                  locations.
                </p>
              </div>
            </div>

            <div className="card area-info-container">
              <div className="card-title has-text-centered">
                <SectionTitle text="Area Information" />
              </div>
              <div className="info-card-content">
                <div className="basalt-info-item">
                  <a href="http://www.basaltchamber.org/town/">Basalt Chamber of Commerce</a>
                </div>
                <div className="basalt-info-item">
                  <a href="http://www.basaltchamber.org/chambermaster/events/annual-events/">Basalt Annual Events</a>
                </div>
                <div className="basalt-info-item">
                  <a href="https://www.colorado.com/cities-and-towns/basalt">Colorado.com - About Basalt</a>
                </div>
                <div className="basalt-info-item">
                  <a href="https://www.tripadvisor.com/Attractions-g33308-Activities-Basalt_Colorado.html">
                    Trip Advisor - Things to do in Basalt
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SectionWrapper>
      <SectionWrapper>
        <div className="columns">
          {page.activities.map((card, i) => (
            <div className="column">
              <CardGridItem
                title={card.title}
                image={card.image}
                description={card.description}
                link={card.link}
                key={i}
              />
            </div>
          ))}
        </div>
      </SectionWrapper>
    </Layout>
  );
};

export default BasaltActivitiesPage;

export const query = graphql`
  query BasaltPageQuery {
    site: siteJson {
      siteTitle
    }
    basalt: basaltJson {
      title
      content {
        childMarkdownRemark {
          html
          frontmatter {
            title
            metaDescription
          }
        }
      }
      imageBasalt {
        src
        caption
      }
      activities {
        title
        description
        image {
          src
          caption
        }
        link {
          url
          label
        }
      }
    }
  }
`;
